import axios from 'axios';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import DOMElementRenderer from '../../containers/dom-element-renderer.jsx';
import GreenFlagOverlay from '../../containers/green-flag-overlay.jsx';
import MonitorList from '../../containers/monitor-list.jsx';
import Question from '../../containers/question.jsx';
import TargetHighlight from '../../containers/target-highlight.jsx';
import { STAGE_DISPLAY_SIZES } from '../../lib/layout-constants.js';
import { getStageDimensions } from '../../lib/screen-utils.js';
import Box from '../box/box.jsx';
import Loupe from '../loupe/loupe.jsx';
import MicIndicator from '../mic-indicator/mic-indicator.jsx';
import styles from './stage.css';

const StageComponent = (props) => {
    const {
        canvas,
        dragRef,
        isColorPicking,
        isFullScreen,
        isStarted,
        colorInfo,
        micIndicator,
        question,
        stageSize,
        useEditorDragStyle,
        onDeactivateColorPicker,
        onDoubleClick,
        onQuestionAnswered,
        ...boxProps
    } = props;

    // 获取当前页面是否处于 `isplayerOnly` 模式
    const searchParams = new URLSearchParams(window.location.search);
    let isShow = searchParams.get('isplayerOnly');
    if (isShow === null) {
        isShow = isFullScreen;
    }

    const stageDimensions = getStageDimensions(stageSize, isShow);

    const handleTakeScreenshot = () => {
        if (canvas) {
            const startTime = Date.now();
            let fistBlob;
            let fistLength = 0;

            // 获取第一个截图的 Blob 和大小
            canvas.toBlob((blob) => {
                fistBlob = blob;
                fistLength = blob.size; // 获取 Blob 的大小
            }, 'image/png'); // 指定 MIME 类型为 PNG

            let count = 0;

            const checkScreenshot = async () => {
                canvas.toBlob(async (blob) => {
                    const imageBlob = blob;
                    const imageSize = imageBlob.size;

                    if (imageSize - fistLength > 100) {
                        // 如果截图尺寸变化大于 100 字节
                        console.log('截图1:', imageBlob);
                        try {
                            console.log('开始转换', Date.now());
                            if (imageBlob) {
                                window.parent.postMessage(
                                    {
                                        type: 'screenshot-url',
                                        value: imageBlob,
                                    },
                                    '*',
                                );
                            }
                        } catch (error) {
                            console.error('发送截图失败:', error);
                        }
                        return;
                    } else if (fistLength - imageSize > 100) {
                        // 如果原始截图和当前截图差异大于 100 字节
                        console.log('截图2:', fistBlob);
                        try {
                            if (fistBlob) {
                                window.parent.postMessage(
                                    {
                                        type: 'screenshot-url',
                                        value: fistBlob,
                                    },
                                    '*',
                                );
                            }
                        } catch (error) {
                            console.error('发送截图失败:', error);
                        }
                        return;
                    } else {
                        count = count + 1;
                        console.log(count);
                        const elapsedTime = Date.now() - startTime;
                        if (elapsedTime < 5000) {
                            setTimeout(checkScreenshot, 50); // 继续等待 0.05 秒后重试
                        } else {
                            console.error('截图失败');
                        }
                    }
                }, 'image/png');
            };

            checkScreenshot(); // 调用异步函数
        } else {
            console.error('Canvas is not available');
        }
    };

    // 处理来自父窗口的消息
    const handleIframeMessage = (event) => {
        if (event.data.type === 'getCover') {
            // 收到消息后执行截图并发送
            handleTakeScreenshot();
        }
    };

    // 在组件挂载时添加和移除消息监听器
    useEffect(() => {
        window.addEventListener('message', handleIframeMessage);
        return () => {
            window.removeEventListener('message', handleIframeMessage);
        };
    }, [canvas]); // 依赖于 canvas

    return (
        <React.Fragment>
            {/* <button onClick={handleTakeScreenshot}>截图</button> */}
            <Box
                className={classNames(styles.stageWrapper, {
                    [styles.withColorPicker]: !isFullScreen && isColorPicking,
                })}
                onDoubleClick={onDoubleClick}
            >
                <Box
                    className={classNames(styles.stage, {
                        [styles.fullScreen]: isFullScreen,
                    })}
                    style={{
                        height: stageDimensions.height,
                        width: stageDimensions.width,
                    }}
                >
                    <DOMElementRenderer
                        domElement={canvas}
                        style={{
                            height: stageDimensions.height,
                            width: stageDimensions.width,
                        }}
                        {...boxProps}
                    />
                    <Box className={styles.monitorWrapper}>
                        <MonitorList
                            draggable={useEditorDragStyle}
                            stageSize={stageDimensions}
                        />
                    </Box>
                    <Box className={styles.frameWrapper}>
                        <TargetHighlight
                            className={styles.frame}
                            stageHeight={stageDimensions.height}
                            stageWidth={stageDimensions.width}
                        />
                    </Box>
                    {isColorPicking && colorInfo ? (
                        <Loupe colorInfo={colorInfo} />
                    ) : null}
                </Box>

                {/* `stageOverlays` is for items that should *not* have their overflow contained within the stage */}
                <Box
                    className={classNames(styles.stageOverlays, {
                        [styles.fullScreen]: isFullScreen,
                    })}
                >
                    <div
                        className={styles.stageBottomWrapper}
                        style={{
                            width: stageDimensions.width,
                            height: stageDimensions.height,
                        }}
                    >
                        {micIndicator ? (
                            <MicIndicator
                                className={styles.micIndicator}
                                stageSize={stageDimensions}
                            />
                        ) : null}
                        {question === null ? null : (
                            <div
                                className={styles.questionWrapper}
                                style={{ width: stageDimensions.width }}
                            >
                                <Question
                                    question={question}
                                    onQuestionAnswered={onQuestionAnswered}
                                />
                            </div>
                        )}
                    </div>
                    <canvas
                        className={styles.draggingSprite}
                        height={0}
                        ref={dragRef}
                        width={0}
                    />
                </Box>
                {isStarted ? null : (
                    <GreenFlagOverlay
                        className={styles.greenFlagOverlay}
                        wrapperClass={styles.greenFlagOverlayWrapper}
                    />
                )}
            </Box>
            {isColorPicking ? (
                <Box
                    className={styles.colorPickerBackground}
                    onClick={onDeactivateColorPicker}
                />
            ) : null}
        </React.Fragment>
    );
};

StageComponent.propTypes = {
    canvas: PropTypes.instanceOf(Element).isRequired,
    colorInfo: Loupe.propTypes.colorInfo,
    dragRef: PropTypes.func,
    isColorPicking: PropTypes.bool,
    isFullScreen: PropTypes.bool.isRequired,
    isStarted: PropTypes.bool,
    micIndicator: PropTypes.bool,
    onDeactivateColorPicker: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onQuestionAnswered: PropTypes.func,
    question: PropTypes.string,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    useEditorDragStyle: PropTypes.bool,
};
StageComponent.defaultProps = {
    dragRef: () => {},
};

export default StageComponent;
